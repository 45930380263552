.role-page{
  .role-page-intro{
    .container{
      p{
        font-size:16px;
        line-height:22px;
      }
    }
  }

  .role-page-lately{
    .container{
      p{
        font-size:16px;
        line-height:22px;
      }
    }
  }

}